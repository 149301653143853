<script setup>
import * as Sentry from "@sentry/nuxt";
const props = defineProps({
  error: {
    type: Object,
    default: {
      statusCode: 404,
      message: '- Page Not Found -',
      stack: ''
    }
  }
})

const { clearError } = useError();
const config = useRuntimeConfig()
Sentry.captureException(props.error);
const env = config.public.nuxtEnvironment;

if ( props.error.statusCode >= 500) {
  props.error.statusCode = 'Oops!';
  props.error.message = 'We\'re currently experiencing some technical difficulties. Please try again later.';  
}

const handleError = () => {    
  props.error = null;
  clearError({
    redirect: "/"
  })
   
};

</script>

<template>
  <LayoutNavBar :topNavColor="'dark'" :toggleHero="false" />
  <div class="main">
    <div class="blocks">
      <div class="block error-block d-flex align-content-center flex-wrap">
        <div class="container">
          <div class="row ">
            <div class="col-12 text-center">
              <h1 class="extra-font-size">{{ error.statusCode }}</h1>
              <h2 class="green-underline">{{ error.message }}</h2>
              <h4 v-html="error.stack" v-if="env !== 'production'"></h4>
              <UIButton @click="handleError">Back Home</UIButton>
            </div>            
          </div>       
        </div>
      </div>
    </div>
  </div>
  <LayoutFooterNavigation />
</template>

<style lang="scss">
.error-block {
  height: 70vh;
  h1 {
    font-size: 10vw;
    font-weight: bold;
    color: $color-primary;
  }
  h4{
    text-align: left;
  }
  button {
    margin-top: 20px;;
  }
}
</style>