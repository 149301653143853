<script setup>
// TODO Better error handling
import {menuItems} from "@/constants/generalData.json";
const { globalFeaturesData} = useGlobalFeaturesData();
const config = useRuntimeConfig()
const logoSVG = `${config.public.pulseAssests}/common-assets/latest/images/id-logo.png`
const props = defineProps({
  hero: {
    type: Boolean,
    default: false
  },
  navColor: {
    type: String,
    default: 'dark'
  },
  promoBanner: {
    type: Boolean,
    default: false
  },
  topNavMenu: {
    type: Boolean,
    default: true
  }
})
// Reactive variables that will change once the properties change
const toggleHero = ref(props.hero)
const topNavColor = ref(props.navColor)

const topNavigation = ref();
const logos = ref();
const expanded = ref(false);
const classes = ref('');

// Getting the data from the server cache first
const { data: globalFeatures, error } = await globalFeaturesData();

topNavigation.value = globalFeatures.value.topNavigation
logos.value = globalFeatures.value.companyLogos

const requestBrochureBtn = topNavigation.value.find(element => element.component === 'top-navigation.cta');
const handleExpanded = () => {
  expanded.value = !expanded.value
}

const sticky = ref(false)
const observer = ref({});

const checkScollPosition = () => {
  if (window.pageYOffset >= 50) {
    sticky.value = true;
    topNavColor.value = 'dark';
    toggleHero.value = false;
  }
  else {
    toggleHero.value = props.hero;
    topNavColor.value = props.navColor;
    sticky.value = !props.hero;
  }
}

const observeHero = () => {
  checkScollPosition();

  window.document.addEventListener("scroll", () => {
    checkScollPosition();
  })
}

onMounted(() => {
  observeHero();
});

onUpdated(() => {
  observeHero();  
});

// Watching the route and if it change and the navbar menu is opened (mobile) then we trigger the click event on the button toggler to hide it.
const route = useRoute();
watch(() => route.path, () => {
  expanded.value = false;
}, {immediate: true, deep: true})

const cartCount = ref(0);
// Shoping cart store
const shoppingCart = useShoppingCart()

// Watching the shoppingCart store to update the cartCount ref variable
watch(() => shoppingCart, () => {
  cartCount.value = shoppingCart.count
}, {immediate: true, deep: true})

const navbarClass = computed(() => {
  return {
    'expanded': expanded.value,
    'dark': topNavColor.value === 'dark',
    'light': topNavColor.value === 'light',
    'position-absolute': toggleHero.value,
    'position-relative': !toggleHero.value,
    'sticky': sticky.value,
    'promo-banner': props.promoBanner
  }
})

const collapseClass = computed(() => {
  return {
    'show': expanded.value
  }
})

const setNavbarState = () => { 
  toggleHero.value = true;
  topNavColor.value = 'light';
}

const ctaClass = `${requestBrochureBtn.buttonStyle} small`
const buttonHexCodeStyle = {
  backgroundColor: requestBrochureBtn.buttonHexCode,
};
defineExpose({
  toggleHero
})

</script>

<template>
  <header id="site-header" class="w-100"  :class="navbarClass">
    <nav class="navbar navbar-expand-lg p-2 pe-sm-1" >
      <div class="container-fluid">
        <a class="id-logo d-none d-lg-block"  href="/">
          <NuxtImg v-if="logos"
            :src="navbarClass.light ? logos.logo.url : logos.logoInverse.url"      
          />
        </a>
        <a class="d-lg-none d-flex mobile-logo" title="Homepage"  href="/">
          <NuxtImg class="mobile-icon" :src="logoSVG"></NuxtImg>
        </a>        
        <div class="mobile-right-links d-flex d-lg-none align-items-center justify-content-between justify-content-sm-end" v-if="topNavMenu">
          <span class="right-links" v-if="requestBrochureBtn">
            <a :href="requestBrochureBtn.buttonLink">
              <UIButton :class="ctaClass" :style="buttonHexCodeStyle">
                {{ requestBrochureBtn.buttonText }}
              </UIButton>
            </a>
          </span>
          <span class="right-links">
          <template v-for="menuItem in menuItems" key="menuItem.id">
            <a class="nav-link pt-1 ms-2" aria-current="page" :href="`${menuItem.url}`" v-if="menuItem.component === 'top-navigation.icon'">
              <span class="bi bi-cart3"></span>
              <span class="cart-count" v-if="cartCount > 0">{{ cartCount }}</span>
            </a>
          </template>
          </span>
          <button class="navbar-toggler p-3 pe-2 pe-sm-3" id="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" :aria-expanded="expanded" aria-label="Toggle navigation" @click="handleExpanded">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        
        <div class="collapse navbar-collapse justify-content-end" :class="collapseClass" v-if="topNavMenu">
          <ul class="navbar-nav nav" :class="!toggleHero ? 'navbar-dark' : ''">
            <template v-for="menuItem in topNavigation" key="menuItem.id">
              <UINavLink v-if="menuItem.component === 'top-navigation.cta'" :menu-item="menuItem" />
              <UIDropdownMenu :menu-id="menuItem.id" :is-dark="false" :menu-item="menuItem" v-if="menuItem.component === 'top-navigation.sub-menu'" />
            </template>
            <template v-for="menuItem in menuItems" key="menuItem.id">
              <UINavLink :menu-item="menuItem" />
            </template>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss">
#site-header {
  z-index: 1000;
  top: 0px;  
  transition: all 500ms ease-in-out;

  &.promo-banner {
    top: 57px;
    &.sticky {
      top: 30px;
    }
  }

  &.sticky {
    position: fixed !important;
    background-color: $color-secondary;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .nav-link .bi-cart3 {
      font-size: 1.5rem;
      color: $color-primary;
    }
  }

  &.expanded {
    height: 100%;
    background-color: white; 
    .nav-link .bi-cart3 {      
      color: $color-primary;
    }
  }

  &.expanded, &.dark {
    .nav-link .bi-cart3 {      
      color: $color-primary;
    }          
    .navbar {
      .navbar-toggler .icon-bar {
        background-color: $color-primary;
      } 
      .navbar-nav {      
        .nav-item .nav-link {
          color: $color-primary;
        }
      }
    }
  }

  .navbar {
    transition: 0.25s ease top;
    border: none;

    .btn {
      font-size: $font-size-small;
      // Temporary until the common css repo is syncronized with bt5
      &.btn-primary {
        background-color: $btn-primary-black-bg;
        color: $btn-primary-black-color;
        border-color: $btn-primary-black-border-color;
        line-height: 1.5;

        &:hover {
          background-color: $btn-primary-black-hover-bg;
        }
      }
      
    }

    .navbar-collapse {
      @include media-breakpoint-down(lg) {
        padding-top: 5px;
      }
    }

    .mobile-logo {
      text-decoration: none;

      .mobile-icon {
        width: 40px;
      }
    }
    .mobile-right-links {      
      max-width: 80%;
      @include media-breakpoint-up(md) {
        max-width: none;        
      }
      flex-grow: 1;
      .right-links {
        .bi-cart3 {
          font-size: 1.5rem;
        }
      }
      
    }
    .navbar-nav {
      @include media-breakpoint-up(lg) {
        align-items: center;
      }
      &.navbar-dark {
        .nav-item .nav-link {
          color: $color-primary;
          &.show, &:hover:not(.btn-request-brochure):not(.bi-cart3) {
            @include media-breakpoint-up(lg) {
              background-color: rgba(#efefef, 0.4);
            }
          }
        }
      }
      .nav-item {
        
        @include media-breakpoint-down(lg) {
          border-top: 1px solid $color-accent; 
        }
        .nav-link {
          font-size: 14px;
          color: white;
          border-radius: $drd-border-radius;

          &.mobile {
            // color: $color-link;
          }

          &.show, &:hover:not(.btn-request-brochure):not(.bi-cart3) {
            @include media-breakpoint-up(lg) {
              background-color: rgba(#ffffff, 0.4);
            }
          }
          
        }

        .dropdown-menu li {
          .dropdown-item {
            font-weight: 600;
            letter-spacing: 0.6px;
            &:hover {
              background: none;
              text-decoration: underline;
            }
          }
        }
      }
    }
    
  }
  .id-logo {
    width: 180px;
    height: 48px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .icon-id-logo {
    font-size: 2.5rem;
    color: $color-id-green;
  }
  .nav-link .bi-cart3 {
    font-size: 1.5rem;
    color: white;
  }
  .navbar-toggler {
    border-width: 0px;    
    &:focus {
      box-shadow: none;
    }
    &[aria-expanded="true"] {
      .icon-bar {
        background-color: $btn-navbar-toggle;
      } 
      .icon-bar:nth-of-type(1) {
        top: 6px;
        transform: rotate(45deg);
      }
      .icon-bar:nth-of-type(2) {
        background-color: transparent;
      }
      .icon-bar:nth-of-type(3) {
        top: -6px;
        transform: rotate(-45deg);
      }
    }
    .icon-bar {
      &:first-of-type {
        margin-top: 0px;
      }
      display: block;
      width: 24px;
      margin: 4px 0 0;
      height: 2px;
      border-radius: 1px;
      position: relative;
      transition: all 500ms ease-in-out;
      background-color: white;
    }
  }

  .cart-count {
    position: relative;
    width: 20px;
    height: 20px;
    font-size: $font-size-small;
    background-color: $color-id-green;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 20px; /* Ensure text is vertically centered */
    box-shadow: 0 17px 31px 0 rgba(129, 107, 107, 0.08);
    display: inline-flex; /* Ensure the circle stays inline with other elements */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    vertical-align: top;
  }
}

</style>