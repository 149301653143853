import { default as _91_46_46_46slug_93pelBYDGXPBMeta } from "/var/www/html/pages/[...slug].vue?macro=true";
import { default as indexbfGFBtd1tbMeta } from "/var/www/html/pages/account/index.vue?macro=true";
import { default as _91slug_935527E5MsPWMeta } from "/var/www/html/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93qWDUo7guTOMeta } from "/var/www/html/pages/blog/authors/[slug].vue?macro=true";
import { default as _91slug_93RqmnBCXN0wMeta } from "/var/www/html/pages/blog/categories/[slug].vue?macro=true";
import { default as indexWKv9cdfpAMMeta } from "/var/www/html/pages/blog/index.vue?macro=true";
import { default as index0gdU1GEMLRMeta } from "/var/www/html/pages/brochure-request/index.vue?macro=true";
import { default as indexbVHEHYT6JcMeta } from "/var/www/html/pages/courses-list/index.vue?macro=true";
import { default as _91slug_93qQRvHJyoipMeta } from "/var/www/html/pages/courses/[slug].vue?macro=true";
import { default as indexzoq7hwBDxvMeta } from "/var/www/html/pages/courses/index.vue?macro=true";
import { default as indexdcuesPTcp6Meta } from "/var/www/html/pages/in-person/index.vue?macro=true";
import { default as indexqINXWxKNI9Meta } from "/var/www/html/pages/location-search/index.vue?macro=true";
import { default as _91slug_93ijnyNR7iEPMeta } from "/var/www/html/pages/locations/[state]/[slug].vue?macro=true";
import { default as indexZAjPC2CNK8Meta } from "/var/www/html/pages/locations/index.vue?macro=true";
import { default as indexDuVpbJbluuMeta } from "/var/www/html/pages/privacy-policy/index.vue?macro=true";
import { default as indexl4GSo1dR38Meta } from "/var/www/html/pages/privacy-policy/privacy-notice-for-california-residents/index.vue?macro=true";
import { default as indexfV1005RD0YMeta } from "/var/www/html/pages/private-lessons/index.vue?macro=true";
import { default as index0Bp0hI6HYAMeta } from "/var/www/html/pages/register/index.vue?macro=true";
import { default as indexRogoxGEbT9Meta } from "/var/www/html/pages/s2/index.vue?macro=true";
import { default as index4BpVEhvYG9Meta } from "/var/www/html/pages/shopping-cart/index.vue?macro=true";
import { default as indexWXbCszQzcrMeta } from "/var/www/html/pages/terms-conditions/index.vue?macro=true";
import { default as indexeI9ZTs3otdMeta } from "/var/www/html/pages/terms-conditions/location-specific/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pelBYDGXPBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pelBYDGXPBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pelBYDGXPBMeta || {},
    alias: _91_46_46_46slug_93pelBYDGXPBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pelBYDGXPBMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexbfGFBtd1tbMeta?.name ?? "account",
    path: indexbfGFBtd1tbMeta?.path ?? "/account",
    meta: indexbfGFBtd1tbMeta || {},
    alias: indexbfGFBtd1tbMeta?.alias || [],
    redirect: indexbfGFBtd1tbMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935527E5MsPWMeta?.name ?? "blog-slug",
    path: _91slug_935527E5MsPWMeta?.path ?? "/blog/:slug()",
    meta: _91slug_935527E5MsPWMeta || {},
    alias: _91slug_935527E5MsPWMeta?.alias || [],
    redirect: _91slug_935527E5MsPWMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qWDUo7guTOMeta?.name ?? "blog-authors-slug",
    path: _91slug_93qWDUo7guTOMeta?.path ?? "/blog/authors/:slug()",
    meta: _91slug_93qWDUo7guTOMeta || {},
    alias: _91slug_93qWDUo7guTOMeta?.alias || [],
    redirect: _91slug_93qWDUo7guTOMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/blog/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RqmnBCXN0wMeta?.name ?? "blog-categories-slug",
    path: _91slug_93RqmnBCXN0wMeta?.path ?? "/blog/categories/:slug()",
    meta: _91slug_93RqmnBCXN0wMeta || {},
    alias: _91slug_93RqmnBCXN0wMeta?.alias || [],
    redirect: _91slug_93RqmnBCXN0wMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/blog/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWKv9cdfpAMMeta?.name ?? "blog",
    path: indexWKv9cdfpAMMeta?.path ?? "/blog",
    meta: indexWKv9cdfpAMMeta || {},
    alias: indexWKv9cdfpAMMeta?.alias || [],
    redirect: indexWKv9cdfpAMMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index0gdU1GEMLRMeta?.name ?? "brochure-request",
    path: index0gdU1GEMLRMeta?.path ?? "/brochure-request",
    meta: index0gdU1GEMLRMeta || {},
    alias: index0gdU1GEMLRMeta?.alias || [],
    redirect: index0gdU1GEMLRMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/brochure-request/index.vue").then(m => m.default || m)
  },
  {
    name: indexbVHEHYT6JcMeta?.name ?? "courses-list",
    path: indexbVHEHYT6JcMeta?.path ?? "/courses-list",
    meta: indexbVHEHYT6JcMeta || {},
    alias: indexbVHEHYT6JcMeta?.alias || [],
    redirect: indexbVHEHYT6JcMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/courses-list/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qQRvHJyoipMeta?.name ?? "courses-slug",
    path: _91slug_93qQRvHJyoipMeta?.path ?? "/courses/:slug()",
    meta: _91slug_93qQRvHJyoipMeta || {},
    alias: _91slug_93qQRvHJyoipMeta?.alias || [],
    redirect: _91slug_93qQRvHJyoipMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/courses/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzoq7hwBDxvMeta?.name ?? "courses",
    path: indexzoq7hwBDxvMeta?.path ?? "/courses",
    meta: indexzoq7hwBDxvMeta || {},
    alias: indexzoq7hwBDxvMeta?.alias || [],
    redirect: indexzoq7hwBDxvMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: indexdcuesPTcp6Meta?.name ?? "in-person",
    path: indexdcuesPTcp6Meta?.path ?? "/in-person",
    meta: indexdcuesPTcp6Meta || {},
    alias: indexdcuesPTcp6Meta?.alias || [],
    redirect: indexdcuesPTcp6Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/in-person/index.vue").then(m => m.default || m)
  },
  {
    name: indexqINXWxKNI9Meta?.name ?? "location-search",
    path: indexqINXWxKNI9Meta?.path ?? "/location-search",
    meta: indexqINXWxKNI9Meta || {},
    alias: indexqINXWxKNI9Meta?.alias || [],
    redirect: indexqINXWxKNI9Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/location-search/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ijnyNR7iEPMeta?.name ?? "locations-state-slug",
    path: _91slug_93ijnyNR7iEPMeta?.path ?? "/locations/:state()/:slug()",
    meta: _91slug_93ijnyNR7iEPMeta || {},
    alias: _91slug_93ijnyNR7iEPMeta?.alias || [],
    redirect: _91slug_93ijnyNR7iEPMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/locations/[state]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZAjPC2CNK8Meta?.name ?? "locations",
    path: indexZAjPC2CNK8Meta?.path ?? "/locations",
    meta: indexZAjPC2CNK8Meta || {},
    alias: indexZAjPC2CNK8Meta?.alias || [],
    redirect: indexZAjPC2CNK8Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexDuVpbJbluuMeta?.name ?? "privacy-policy",
    path: indexDuVpbJbluuMeta?.path ?? "/privacy-policy",
    meta: indexDuVpbJbluuMeta || {},
    alias: indexDuVpbJbluuMeta?.alias || [],
    redirect: indexDuVpbJbluuMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4GSo1dR38Meta?.name ?? "privacy-policy-privacy-notice-for-california-residents",
    path: indexl4GSo1dR38Meta?.path ?? "/privacy-policy/privacy-notice-for-california-residents",
    meta: indexl4GSo1dR38Meta || {},
    alias: indexl4GSo1dR38Meta?.alias || [],
    redirect: indexl4GSo1dR38Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/privacy-policy/privacy-notice-for-california-residents/index.vue").then(m => m.default || m)
  },
  {
    name: indexfV1005RD0YMeta?.name ?? "private-lessons",
    path: indexfV1005RD0YMeta?.path ?? "/private-lessons",
    meta: indexfV1005RD0YMeta || {},
    alias: indexfV1005RD0YMeta?.alias || [],
    redirect: indexfV1005RD0YMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/private-lessons/index.vue").then(m => m.default || m)
  },
  {
    name: index0Bp0hI6HYAMeta?.name ?? "register",
    path: index0Bp0hI6HYAMeta?.path ?? "/register",
    meta: index0Bp0hI6HYAMeta || {},
    alias: index0Bp0hI6HYAMeta?.alias || [],
    redirect: index0Bp0hI6HYAMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexRogoxGEbT9Meta?.name ?? "s2",
    path: indexRogoxGEbT9Meta?.path ?? "/s2",
    meta: indexRogoxGEbT9Meta || {},
    alias: indexRogoxGEbT9Meta?.alias || [],
    redirect: indexRogoxGEbT9Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/s2/index.vue").then(m => m.default || m)
  },
  {
    name: index4BpVEhvYG9Meta?.name ?? "shopping-cart",
    path: index4BpVEhvYG9Meta?.path ?? "/shopping-cart",
    meta: index4BpVEhvYG9Meta || {},
    alias: index4BpVEhvYG9Meta?.alias || [],
    redirect: index4BpVEhvYG9Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/shopping-cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexWXbCszQzcrMeta?.name ?? "terms-conditions",
    path: indexWXbCszQzcrMeta?.path ?? "/terms-conditions",
    meta: indexWXbCszQzcrMeta || {},
    alias: indexWXbCszQzcrMeta?.alias || [],
    redirect: indexWXbCszQzcrMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexeI9ZTs3otdMeta?.name ?? "terms-conditions-location-specific",
    path: indexeI9ZTs3otdMeta?.path ?? "/terms-conditions/location-specific",
    meta: indexeI9ZTs3otdMeta || {},
    alias: indexeI9ZTs3otdMeta?.alias || [],
    redirect: indexeI9ZTs3otdMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/terms-conditions/location-specific/index.vue").then(m => m.default || m)
  }
]