
export const useRawPageData = () => {

  const config = useRuntimeConfig()
  const transform = (response) => {
    const { sanitize } = useUtils();
    return {
      id: response.data.id,
      title: sanitize(response.data.attributes.title),
      slug: sanitize(response.data.attributes.slug),
      content: sanitize(response.data.attributes.content),
      jumpToTop: sanitize(response.data.attributes.jumpToTop),
      seo: sanitize(response.data.attributes.seo)
    }
  }
  
  const getRawPageBySlug = async (slug) => {    
    const {findOneBySlug} = useStrapiAsyncData();
    const url = 'raw-pages';
    const params = {
      slug: slug,
      entity: 'raw-pages'
    }
    return findOneBySlug(url, params, transform);
  }

  const getCompanyPage = async (id) => {
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(`/api/company-configurable-text?configTypeId=${id}`, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch(`/api/company-configurable-text?configTypeId=${id}`, {
      method: 'GET'
    })
    
  }

  
  
  return {
    getRawPageBySlug,
    getCompanyPage
  }
}