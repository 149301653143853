import revive_payload_client_4sVQNw7RlN from "/var/www/html/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/html/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/html/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/var/www/html/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_OrkQhMqHci from "/var/www/html/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/html/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/var/www/html/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import apiTools_dktkB75FLk from "/var/www/html/plugins/apiTools.js";
import optimizely_plugin_Gr0AM4DEA6 from "/var/www/html/plugins/optimizely-plugin.js";
import sentry_client_GoGQuZo4Et from "/var/www/html/plugins/sentry.client.js";
import useBootstrap_client_ouDJvcVteA from "/var/www/html/plugins/useBootstrap.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  apiTools_dktkB75FLk,
  optimizely_plugin_Gr0AM4DEA6,
  sentry_client_GoGQuZo4Et,
  useBootstrap_client_ouDJvcVteA
]