import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import offcanvas from 'bootstrap/js/dist/offcanvas'
import carousel from 'bootstrap/js/dist/carousel'
import collapse from 'bootstrap/js/dist/collapse'
import toast from 'bootstrap/js/dist/toast'

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      offcanvas,
      toast,
      carousel
      // collapse,
      
    }
  }
})