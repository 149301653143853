export const useCourseData = () => {
  const {sanitize} = useUtils();
  const config = useRuntimeConfig()
  const transformCatalog = (response) => {   
    
    const data = {
      id: response.data.id,        
      body: response.data.attributes.body,
      onCampusFilters: response.data.attributes.onCampusFilters,
      onlineFilters: response.data.attributes.onlineFilters,
      heroHeader: sanitize(response.data.attributes.heroHeader),
      noResultsAvailable: response.data.attributes.noResultsAvailable,
      jumpToTop: response.data.attributes.jumpToTop,
      displayHero: response.data.attributes.displayHero,
      brochureRibbon: response.data.attributes.brochureRibbon,
      brochureRibbonTags: response.data.attributes.tagsArray,
      seo: sanitize(response.data.attributes.seo),
      showChatService: response.data.attributes.showChatService,
      openNewTab: response.data.attributes.openNewTab,
      onCampusExperience: response.data.attributes.onCampusExperience,
    }
    return data;
  }

  const transformCatalogGrid = (response) => {   
    
    const data = {
      id: response.data.id,
      heroHeader: sanitize(response.data.attributes.heroHeader),
      seo: sanitize(response.data.attributes.seo),
      ...response.data.attributes,
    }
    return data;
  }

  const tranformFloatingBlocks = (response) => { 
    const data = {
      ...response.data.attributes,
      image: response.data.attributes.image.data.attributes
    }
    return data;
  }

  const getFBPricesDates = async () => {        
    const url = `/api/fb-prices-dates`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: tranformFloatingBlocks 
      });
    }

    return await useFetch(url, {
      method: 'GET',
      baseURL: config.public.nuxtAPIUrl,
      transform: tranformFloatingBlocks 
    });
  }

  const getFBSelectPackage = async () => {        
    const url = `/api/fb-select-package`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: ((response) => {
          return {
            ...response.data.attributes
          }
        }) 
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: ((response) => {
        return {
          ...response.data.attributes
        }
      }) 
    });
    
  }

  const getFBSelectProgram = async () => {        
    const url = `/api/fb-select-program`;
    
    return await useFetch(url, {
      method: 'GET',
      transform: ((response) => {
        return {
          ...response.data.attributes
        }
      }) 
    });
  }

  const getFBGeolocationLookup = async () => {        
    const url = `/api/fb-geolocation-lookup`;
    
    return await useFetch(url, {
      method: 'GET',
      transform: ((response) => {
        return {
          ...response.data.attributes
        }
      }) 
    });
  }

  const getFBLocationSearch = async () => {        
    const url = `/api/fb-location-search`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: tranformFloatingBlocks 
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: tranformFloatingBlocks 
    });
    
  }

  const getFBViewLocations = async () => {        
    const url = `/api/fb-view-locations`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: tranformFloatingBlocks
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: tranformFloatingBlocks
    });
    
  }

  const transformCourse = (response) => { 
    const data = {
      ...response.data.attributes,
      blocks: sanitize(response.data.attributes.blocks.map((element) => {
        const result = {
          ...element,
          component: element.__component,
        }
        delete result.__component;
        return result;
      })), 
      pulseCourse: response.data.attributes.pulseCourse !== null ? 
      {
        ...response.data.attributes.pulseCourse.data.attributes,
        id: response.data.attributes.pulseCourse.data.id,

      } : null,
      carousel: response.data.attributes.carousel,
      price: response.data.attributes.price,
      aboutCourseTitle: response.data.attributes.aboutCourseTitle,
      topicsCoveredTitle: response.data.attributes.topicsCoveredTitle,
      brochureRibbon: response.data.attributes.brochureRibbon,
      brochureRibbonTags: response.data.attributes.tagsArray,
      jumpToTop: response.data.attributes.jumpToTop,
      seo: sanitize(response.data.attributes.seo),
      publishedAt: sanitize(response.data.attributes.publishedAt),
      showChatService: response.data.attributes.showChatService
    }
    return data;
  }

  const transformLeadGen = (response) => {
    const result = {
      ...response.data.attributes
    }
    return result;
  }

  const transform = (response) => {
    const data = response.data.map((element) => {
      return {
        id: element.id,        
        title: element.attributes.title,
        slug: element.attributes.slug,
        showOnCatalog: element.attributes.showOnCatalog,
        pulseId: element.attributes.pulseId,        
        image: sanitize(element.attributes.image),
        pulseCourse: element.attributes.pulseCourse,
        isVirtual: element.attributes.isVirtual,
        isOnlinePrivateLesson: element.attributes.isOnlinePrivateLesson,
        isVTC: element.attributes.isVTC,
        isVTA: element.attributes.isVTA,
        isTeenAcademies3Week: element.attributes.isTeenAcademies3Week,
        isBootcamp: element.attributes.isBootcamp,
        isOnlineCamps: element.attributes.isOnlineCamps,
        isWeeklyClasses: element.attributes.isWeeklyClasses,
        isTechCamps: element.attributes.isTechCamps,
        isTechAcademies: element.attributes.isTechAcademies, 
        courseType: element.attributes.courseType,
        variants: element.attributes.variants,
        ...element.attributes       
      }
    })      
    return {
      data: data,        
      meta: response.meta
    }
  }

  const getCourseCatalog = async () => {        
    const url = `/api/course-catalog`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformCatalog
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformCatalog
    });
    
  }

  const getCourses = async () => {
    const url = `/api/courses`; 

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
  }

  const courseLeadGen = async (deep = true) => {
    const url = `/api/course-floating-block-lead-gen`

    if (config.public.nuxtEnvironment === 'production') {
      let res = await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformLeadGen
      })
      return res.data
    }

    let res = await useFetch(url, {
      method: 'GET',
      transform: transformLeadGen
    })
    return res.data
    
  }

  const getCourseBySlug = async (slug) => {    
    const {findOneBySlug} = useStrapiAsyncData();
    const url = 'courses';
    const params = {
      slug: slug,
      entity: url
    }
    return findOneBySlug(url, params, transformCourse);
  }

  const getCourseById = async (id) => {    
    const url = `/api/courses?id=${id}`; 

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformCourse
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformCourse
    });

    
  }

  const getPulseCourseById = async (id) => {    
    const url = `/api/pulse-course?id=${id}`; 

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
      });
    }
    
    return await useFetch(url, {
      method: 'GET',
    });
  }

  const genres = (course) => {
    const result = course.genres.map(g => g.name)
    return result.join(', ')
  }
  
  const ages = (course) => {  
    return `Ages ${course.minAge}-${course.maxAge}`
  }
  
  const skillFullNames = (skillId) => {
    const fullNames = {
      51: 'Beginner',
      52: 'Intermediate',
      53: 'Advanced'
    }
  
    return fullNames[skillId] ? fullNames[skillId] : 'N/A';
  
  }
  
  const skillLevels = (course, abbrv = false) => {
    if (course.skillLevels.length == 0) {
      return '';
    }
      
    const sorted = course.skillLevels.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });    
    
    if (sorted.length == 1) {
      return skillFullNames(sorted[0].id);
    }

    const levels = `${skillFullNames(sorted[0].id)}-${skillFullNames(sorted[1].id)}`;

    if (abbrv) {      
      return levels.replace('Beginner', 'Beg').replace('Intermediate', 'Int').replace('Advanced', 'Adv')
    }
    
    return levels;
  }
  
  const duration = (course) => {
    if (course.experiences.length == 0) {
      return '';
    }
  
    return course.experiences[0].shortName;
  }

  const format = (course) => {
    if (course.experiences.length == 0) {
      return '';
    }
    return course.experiences[0].name;
  }
  
  const getCourseCatalogGrid = async (id) => {    
    const url = `/api/course-catalog-grid`; 

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformCatalogGrid
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformCatalogGrid
    });

    
  }

  const getCoursesGrid = async () => {
    const url = `/api/courses-grid`; 

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
  }

  const manageFilters = (items, selected = ['All']) => {
    const options = []
    items.forEach(element => {
      if (element === 'All (default)') {
        options.push({ value: 'All', label: 'All', checked: selected.includes('All')})
      }
      else {
        options.push({ value: element, label: element, checked: selected.includes(element)})
      } 
    });

    return options;
  }

  const getAgeRange = (age, filter = null) => {
    if (filter === null) {
      return age <= 9 ? ['7-9'] : age <= 12 ? ['10-12'] : age <= 19 ? ['13-19'] : ['All']
    }
    else {
      const result = filter.find((range) => {      
        const split = range.split('-');
        const min = parseInt(split[0]), max = parseInt(split[1]);
        return parseInt(age) >= min && parseInt(age) <= max; 
      })
      return result;
    }
    
  }

  const setupGridFilters = (filterType, filter) => {
    if (filterType === 'ages' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? [getAgeRange(filter.selected, filter.options)] : ['All'];      
      const gridFilter = {
        id: filter.id,
        name: filter.name,
        sortOrder: filter.sortOrder,
        inputName: 'ages',
        selected: selected,
        options: [
          {value: 'All', label: 'All', checked: selected === 'All'},
          {value: '7-9', label: '7-9', checked: selected === '7-9'},
          {value: '10-12', label: '10-12', checked: selected === '10-12'},
          {value: '13-19', label: '13-19', checked: selected === '13-19'},
        ]
      }      
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageFilters(filter.options, selected)
      }
    
      return gridFilter;
    
    }

    if (filterType === 'interests' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: filter.id,
        name: filter.name,
        sortOrder: filter.sortOrder,
        inputName: 'interests',
        selected: selected,
        options: [
          {value: 'All', label: 'All', checked: true},
          {value: 'Coding', label: 'Coding'},
          {value: 'Game Dev', label: 'Game Dev'},
          {value: 'Robotics', label: 'Robotics'},
          {value: 'Creative', label: 'Creative'},
          {value: 'Business', label: 'Business'},
          {value: 'Math', label: 'Math'},
        ]
      }      
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageFilters(filter.options, selected)
      }
    
      return gridFilter;
    
    }

    if (filterType === 'programs' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: filter.id,
        name: filter.name,
        sortOrder: filter.sortOrder,
        inputName: 'programs',
        selected: selected,
        options: [
          {value: 'All', label: 'All', checked: true},
          {value: 'Tech Camps', label: 'Tech Camps'},
          {value: 'Teen Academies', label: 'Teen Academies'},
          {value: 'Private Lessons', label: 'Private Lessons'},
        ]
      }
    
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageFilters(filter.options, selected)
      }
    
      return gridFilter;
    
    }

    if (filterType === 'format' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: filter.id,
        name: filter.name,
        sortOrder: filter.sortOrder,
        inputName: 'format',
        selected: selected,
        options: [
          {value: 'All', label: 'All', checked: true},
          {value: 'On-Campus', label: 'On-Campus'},
          {value: 'Online', label: 'Online'},
        ]
      }
    
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageFilters(filter.options, selected)
      }
    
      return gridFilter;
    
    }
  }

  const manageMobileFilters = (items, type = 'normal') => {
    const options = []
    items.forEach(element => {
      if (element === 'All (default)') {
        options.push('All')
      }
      else {
        if (type === 'ages') {
          options.push(`Ages ${element}`)
        }
        else {
          options.push(element)
        }
      } 
    });

    return options;
  }  

  const setupMobileGridFilters = (filterType, filter) => {
    if (filterType === 'ages' && filter && filter.display) {
      let selected = ['All'];
      if (filter.selected && filter.selected.length > 0 && filter.selected[0] !== 'All') {
        const range = filter.selected[0].replace('-', ' - ')
        selected = [`Ages ${range}`];
      }
      
      const gridFilter = {
        id: `ages-${filter.sortOrder}`,
        title: filter.name,
        type: 'ages',
        all: 'All',
        value: selected,        
        order: filter.sortOrder,        
        options: [
          'All',
          'Ages 7-9',
          'Ages 10-12',
          'Ages 13-19'
        ]
      }
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageMobileFilters(filter.options, 'ages')
      }
    
      return gridFilter;
    
    }

    if (filterType === 'interests' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: `interests-${filter.sortOrder}`,
        title: filter.name,
        type: 'interests',
        all: 'All',
        value: selected,        
        order: filter.sortOrder,
        options: [
          'All', 'Coding', 'Game Dev',  'Robotics',  'Creative',  'Business',  'Math'
        ]
      }
    
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageMobileFilters(filter.options)
      }
    
      return gridFilter;
    
    }

    if (filterType === 'programs' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: `programs-${filter.sortOrder}`,
        title: filter.name,
        type: 'programs',
        all: 'All',
        value: selected,        
        order: filter.sortOrder,
        options: [
          'All', 'Tech Camps', 'Teen Academies', 'Private Lessons'
        ]
      }
    
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageMobileFilters(filter.options)
      }
    
      return gridFilter;
    
    }

    if (filterType === 'format' && filter && filter.display) {
      const selected = filter.selected && filter.selected.length > 0 ? filter.selected : ['All'];
      const gridFilter = {
        id: `format-${filter.sortOrder}`,
        title: filter.name,
        type: 'format',
        all: 'All',
        value: selected,        
        order: filter.sortOrder,
        options: [
          'All', 'On-Campus', 'Online'
        ]
      }
    
      // In case ages are being defined in Strapi we will get it from there otherwise we keep the default values
      if (filter.options.length > 0) {
        gridFilter.options = manageMobileFilters(filter.options)
      }
    
      return gridFilter;
    
    }
  }
  

  return {
    getCourseCatalog,
    getCourses,
    getCourseBySlug,
    genres,
    ages,
    skillLevels,
    duration,
    format,
    getFBLocationSearch,
    getFBViewLocations,
    courseLeadGen,   
    getFBPricesDates,
    getFBSelectPackage,
    getFBSelectProgram,
    getCourseById,
    getPulseCourseById,
    getCourseCatalogGrid,
    getCoursesGrid,
    setupGridFilters,
    setupMobileGridFilters,
    getAgeRange,
    getFBGeolocationLookup
  }
}
