export const useLocationData = () => {

  const config = useRuntimeConfig()

  const transform = (response) => {
    const data = response.data.map((element) => {
      const { sanitize } = useUtils();
      return {
        id: element.id,        
        name: element.attributes.name,
        slug: element.attributes.slug,
        description: element.attributes.description,
        listingImage: element.attributes.listingImage === null ? null : element.attributes.listingImage.data.attributes,
        pulseLocation: element.attributes.pulseLocation === null ? null : element.attributes.pulseLocation.data.attributes,
        distance: element.attributes.distance,
        url:  element.attributes.url,
        pulseId: element.attributes.pulseId,
        marketingText: element.attributes.marketingText,
      }
    })      
    return {
      data: data,        
      meta: response.meta
    }
  }

  const transformDrawer = (response) => {
    
    return {
      locationsWithinRadius: response.updatedlocationsWithinRadius,        
      otherLocations: response.updatedOtherLocations
    }
  }

  const transformLocation = (response) => {
    const { sanitize } = useUtils();
    return {
      locations: response.data.attributes.locations,
      nearest: response.data.attributes.nearest,
      entity: {
        ...response.data.attributes.entity,
        listingImage: response.data.attributes.entity.listingImage === null ? null : response.data.attributes.entity.listingImage,
        heroGallery: response.data.attributes.entity.heroGallery === null ? null : response.data.attributes.entity.heroGallery,
        overview: sanitize(response.data.attributes.entity.overview.map((element) => {
          const result = {
            ...element,
            component: element.__component,
          }
          delete result.__component;
          return result;
        })),
        campusLife: sanitize(response.data.attributes.entity.campusLife.map((element) => {
          const result = {
            ...element,
            component: element.__component,
          }
          delete result.__component;
          return result;
        })),
        instructors: sanitize(response.data.attributes.entity.instructors.map((element) => {
          const result = {
            ...element,
            component: element.__component,
          }
          delete result.__component;
          return result;
        })),
        faqs: sanitize(response.data.attributes.entity.faqs.map((element) => {
          const result = {
            ...element,
            component: element.__component,
          }
          delete result.__component;
          return result;
        })),
        pulseLocation: {
          ...response.data.attributes.entity.pulseLocation,
          status: {
            name: response.data.attributes.entity.pulseLocation.status.name,
            typeId: parseInt(response.data.attributes.entity.pulseLocation.status.typeId)
          }
        }
      },
      seo: sanitize(response.data.attributes.seo),
      publishedAt: sanitize(response.data.attributes.publishedAt)
    }
  }

  const transformShared = (response) => {
    const { sanitize } = useUtils();
    const rawGallery = sanitize(response.data.attributes.sharedHeroGallery.data);
    const heroGallery = rawGallery.map((element) => {
      return {
        id: element.id,
        ...element.attributes
      }
    })
    
    const result = {
      ...response.data.attributes,      
      sharedHeroGallery: heroGallery
    }

    return result;
  }

  const transformLeadGen = (response) => {
    const result = {
      ...response.data.attributes
    }
    return result;
  }
  const transformShortForm = (response) => {
    const { sanitize } = useUtils();
    const result = {
      ...response.data.attributes
    }

    return result;
  }

  

  const transformSettings = (response) => {
    const {sanitize} = useUtils();
    const result = {
      id: response.data.id,        
      flexibleProgramsTitle: response.data.attributes.flexibleProgramsTitle,
      navigationMenu: response.data.attributes.navigationMenu,      
      heroHeader: sanitize(response.data.attributes.heroHeader),
      jumpToTop: response.data.attributes.jumpToTop,
      displayHero: response.data.attributes.displayHero,
      brochureRibbon: response.data.attributes.brochureRibbon,
      brochureRibbonTags: response.data.attributes.tagsArray,
      seo: sanitize(response.data.attributes.seo),
      showChatService: response.data.attributes.showChatService
    } 

    return result;
  }



  const locationsByState = async (filters) => {
    const { data: result, error } = await find(200);
    const catalog = {};
  
    if (!error.value) {
      result.value.data.forEach((element) => {
        const state = element.pulseLocation.address.state;
        const subRegion = element.pulseLocation.address.subRegion;
        const country = element.pulseLocation.address.country;
  
        if (country === 'United States') {
          if (
            state &&
            element.pulseLocation.locationTypeID !== 14002 &&
            ((filters.length === 1 && filters[0] === 'all') ||
              filters.some(
                (d) =>
                  element.pulseLocation.divisions &&
                  element.pulseLocation.divisions.indexOf(Number(d)) >= 0
              ))
          ) {
            element.url = `${slugify(state)}-summer-camps/${element.slug}`;
  
            const key = subRegion ? `${state} - ${subRegion}` : state;
  
            if (!catalog[key]) {
              catalog[key] = [element];
            } else {
              catalog[key].push(element);
            }
          }
        }
      });
    }
  
    return Object.keys(catalog)
      .filter((key) => {
        const stateData = catalog[key];
        // Check if there is at least one entry with a status that is not 'inactive'
        return stateData.some(
          (entry) => entry.pulseLocation.status.typeId !== "-1"
        );
      })
      .sort()
      .reduce((obj, key) => {
        const stateData = catalog[key].filter(
          (entry) => entry.pulseLocation.status.typeId !== "-1"
        );
        // Only include the state if it has active locations
        if (stateData.length > 0) {
          // Sort locations alphabetically
          stateData.sort((a, b) =>
            a.pulseLocation.name.localeCompare(b.pulseLocation.name)
          );
  
          obj[key] = stateData;
        }
        return obj;
      }, {});
  };
  
  
  const slugify = (string) => {
    return string.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');
  }

  const find = async (pageSize = 25, deep = true) => {
    const paginationSize = pageSize != 25 ? `pagination[pageSize]=${pageSize}` : '';
    const url = `/api/locations?pageSize=${pageSize}`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      }); 
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    }); 
    
  }

  const search = async (searchTerm, page, distance = 250) => {
    
    const url = `/api/location-search?searchTerm=${searchTerm.value}&page=${page}&distance=${distance}`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET', 
        baseURL: config.public.nuxtAPIUrl,     
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
  }

  // TODO Improve this service to cache the locations and also only return Location Ids
  const searchLocationFilter = async (searchTerm, distance = 90) => {
    
    const url = `/api/location-search?searchTerm=${searchTerm}&page=1&pageSize=100&distance=${distance}`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
  }

  const locationSearchDrawer = async (searchTerm, distance = 90) => {
    const url = `/api/location-search-all?searchTerm=${searchTerm}&distance=${distance}`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformDrawer
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformDrawer
    });
  }

  const locationCatalogSettings = async (deep = true) => {    
    const url = `/api/location-catalog-settings`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformSettings
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformSettings
    });
  }

  const locationSharedContent = async (deep = true) => {    
    const url = `/api/location-shared-content`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformShared
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformShared
    });
  }
  const locationShortForm = async (deep = true) => {    
    const url = `/api/location-short-form`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformShortForm
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformShortForm
    });
  }

  const locationLeadGen = async (deep = true) => {
    const url = `/api/lead-location-form`

    if (config.public.nuxtEnvironment === 'production') {
      let res = await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformLeadGen
      })
      return res.data
    }

    let res = await useFetch(url, {
      method: 'GET',
      transform: transformLeadGen
    })
    return res.data
    
  }

  const getLocationBySlug = async (slug) => {
    const url = `/api/location-by-slug?slug=${slug}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transformLocation
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformLocation
    });
    
  }

  const locationsByDistance = async (distances) => {
    const data = await useStorage().getItem('cache:locations')
    const locations = transform(data);
  }

  const locationDockedNavigation = async (deep = true) => {    
    const url = `/api/location-docked-navigation`
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: ((response) => {
          const result = {
            ...response.data.attributes
          }    
          return result;
        })
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: ((response) => {
        const result = {
          ...response.data.attributes
        }    
        return result;
      })
    });
  }

  const getLocationsByDivision = async (division) => {
    const url = `/api/locations-by-division/?division=${division}`;
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl
      });
    }

    return await useFetch(url, {
      method: 'GET'
    });
  }

  const getLocationsByCords = async (params) => {
    // Convert divisionIds array to a comma-separated string
    if (Array.isArray(params.division)) {
      params.division = params.division.join(',');
    }    
    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    const url = `/api/locations-by-coordinates/?${queryString}`;    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl
      });
    }

    return await useFetch(url, {
      method: 'GET'
    });
  }

  const locationFetch = (type) => {
    let url = '';  
    switch(type) {
      case 'leadGenData':
        url = config.public.nuxtEnvironment === 'production' ? `${config.public.nuxtAPIUrl}/api/lead-gen` : `/api/lead-gen`
        return $fetch(url)//transformSettings(response)
      case 'locationSharedContent':
        url = config.public.nuxtEnvironment === 'production' ? `${config.public.nuxtAPIUrl}/api/location-shared-content` : `/api/location-shared-content`
        return $fetch(url)
      case 'locationLeadGen':
        url = config.public.nuxtEnvironment === 'production' ? `${config.public.nuxtAPIUrl}/api/lead-location-form` : `/api/lead-location-form`        
        return $fetch(url)
      case 'locationShortForm':
        url = config.public.nuxtEnvironment === 'production' ? `${config.public.nuxtAPIUrl}/api/location-short-form` : `/api/location-short-form`
        return $fetch(url)
      case 'locationDockedNavigation':
        url = config.public.nuxtEnvironment === 'production' ? `${config.public.nuxtAPIUrl}/api/location-docked-navigation` : `/api/location-docked-navigation`
        return $fetch(url)
    }
  }  

  const transformFetch = (type, response) => {
    switch(type) {
      case 'leadGenData':
        const { sanitize } = useUtils()

        return {
          leadGenFields: sanitize(response.data.attributes.leadGenFields)
        }
      case 'locationSharedContent':
        return transformShared(response)
      case 'locationLeadGen':        
        return transformLeadGen(response)
      case 'locationShortForm':
        return transformShortForm(response)
      case 'find':
        return transform(response)
      case 'search':
        return transform(response)
      case 'locationDockedNavigation':
        const result = {
          ...response.data.attributes
        }
        return result 
    }
  }

  


  return {
    find,
    search,
    locationsByState,
    slugify,
    locationCatalogSettings, 
    searchLocationFilter,
    getLocationBySlug,
    locationSharedContent,
    locationLeadGen,
    locationShortForm,
    locationDockedNavigation,
    getLocationsByDivision,
    locationFetch,
    transformFetch,
    locationSearchDrawer,
    getLocationsByCords
  }
}