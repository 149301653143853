<script setup>
const nuxtApp = useNuxtApp()
nuxtApp.hook("page:finish", () => {
  window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"})
  })

  onMounted(() => {
    document.querySelectorAll('a[href^="#"]').forEach((element) => {    
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const elementId = e.target.hash.replace('#', '');
      const target = document.getElementById(elementId);
      
      if (target) {
        window.scrollTo({
          top: target.offsetTop - 60,
          left: 0,
          behavior: "smooth"
        });

        // Update URL with hash fragment without triggering page reload
        history.pushState(null, null, e.target.hash);
      }
    })
  })
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
