import * as Sentry from '@sentry/vue'


export default defineNuxtPlugin((nuxtApp) => {  
  const config = useRuntimeConfig()


  Sentry.init({
    app: nuxtApp.vueApp,
    // If set up, you can use your runtime config here
    // dsn: useRuntimeConfig().public.sentry.dsn,
    dsn: "https://8da185c4ff08e1c66451521b26d11d89@o4508444598075392.ingest.us.sentry.io/4508447470649344",

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    
    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      Sentry.replayIntegration(), 
      Sentry.breadcrumbsIntegration({ console: false })
    ],
    
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    // Define the environment
    environment: config.public.nuxtEnvironment,    
    beforeSend(event) {
      if (event.level === 'log' || event.level === 'info') {
        return null;
      }
      return event;
    },
  })
})

