<script setup>
const props = defineProps({
  menuItem: Object
})

const experience = useExperience();
const handleClick = (item) => {
  // if (item.url.includes('courses') && item.text.includes('Online')) {
  //   experience.update('online')
  // }
  // else if (item.url.includes('courses') && item.text.includes('On-Campus')) {
  //   experience.update('on-campus')
  // }
  window.location.href = item.url;
}
const menuSubtitle = props.menuItem.menuSubtitle !== null ? props.menuItem.menuSubtitle.trim() : null
</script>

<template>
  <li class="nav-item dropdown px-3 px-md-0 py-1 py-lg-0" v-if="menuItem.menuItems.length > 0 || (menuSubtitle !== null && menuSubtitle !== '' )" >
    <a class="nav-link dropdown-toggle p-2" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" :data-dropdown="menuItem.id">
      {{ menuItem.text }}      
    </a>
    <ul class="dropdown-menu" :aria-labelledby="menuItem.id">
      <li class="menu-header" v-if="menuSubtitle !== null && menuSubtitle !== ''">
        <div class="dropdown-header px-2 px-lg-3"  v-html="menuItem.menuSubtitle">
        </div>
      </li>
      <li v-for="item in menuItem.menuItems" :key="item.id">
        <a class="dropdown-item p-2 px-lg-3" v-if="item.url.includes('courses')" :class="item.url.includes('courses') && item.text.includes('Online') ? 'online-experience' : (item.url.includes('courses') && item.text.includes('On-Campus') ? 'on-campus-experience' : '')" @click="handleClick(item)" >{{ item.text }}</a>
        <a class="dropdown-item p-2 px-lg-3" v-else :href="item.url">{{ item.text }}</a>
      </li>
    </ul>    
  </li>
  <li class="nav-item dropdown px-3 px-md-0 py-1 py-lg-0" v-else="">
    <a class="nav-link p-2" :href="menuItem.url" id="navbarDropdownMenuLink" role="button" >
      {{ menuItem.text }}      
    </a>
  </li>
</template>

<style lang="scss">
.nav-item.dropdown {  
  .dropdown-toggle {
    font-size: 14px;
    &.show {
      &:after {
        transform: rotate(-135deg);
      }
    }
    &::after {
      transition: 0.25s ease all;
      content: "";
      border-top: 0;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      border-left: 0;
      width: 7px;
      height: 7px;
      border-radius: 1px;
      transform: rotate(45deg);
      top: -2px;
      margin-left: 5px;
      vertical-align: 2px;

      @include media-breakpoint-down(lg) {       
        position: absolute;
        right: 20px;
        top: 17px;
      }
    }
    
        
  }
  .dropdown-menu {
    box-shadow: 0 4px 16px 0 rgba($color-black, 0.16);
    border: solid 1px $color-light-gray;
    
    top: calc(100% + 13px);
    margin-top: 0;
    z-index: -1;

    .menu-header {
      .dropdown-header {
        h4, h5, h6 {
          font-size: 18px;
          font-weight: bold;
          color: $color-primary;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }

    .dropdown-item {
      cursor: pointer;
    }
    
    @include media-breakpoint-down(lg) {            
      border: 0;
      box-shadow: none;
      border-radius: 0;

      .dropdown-item {
        padding-left: 0px;
        padding-right: 0px;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.63;
      }
    }
  }

  
}
</style>