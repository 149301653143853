
export const useRedirectData = () => {
  const config = useRuntimeConfig()
  const getRedirects = async () => {        
    const url = `/api/redirects`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
      });
    }
    
    return await useFetch(url, {
      method: 'GET',
      baseURL: config.public.nuxtAPIUrl,
    });
  }
  return { getRedirects };
}