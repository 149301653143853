<script setup>

const err = useError()

const error = {
  statusCode: err.statusCode,
  message: err.message,
  stack: err.stack,
  url: err.url,
  statusMessage: err.statusMessage
}

// ZigZag required scripts and styles
const config = useRuntimeConfig()
const {version} = useZigZag()
const {data, refresh} = await version(config.public.zigzagUrl)
const time = data.value ? data.value.t : 0;
const sha = data.value ? data.value.sha : 'versionnotfound';

if (process.server) {
  useHead({
    
    script: [
      {
        src: `${config.public.zigzagUrl}/${config.public.zigzag3rdParty}?sha=${sha}&t=${time}`
      },
      {
        src: `${config.public.zigzagUrl}/${config.public.zigzagPulseApp}?sha=${sha}&t=${time}`
      }
    ],
    link: [
      {        
        rel: 'stylesheet',
        href: `${config.public.zigzagUrl}/styles/pulseapp.css?sha=${sha}&t=${time}`
      }
    ]
  
  })
}
</script>
<template>
  <LayoutError :error="error" />
</template>