import { defineStore } from 'pinia'

export const useExperience = defineStore('experience', {
  state: () => ({
    type: 'on-campus'
  }),

  persist: {
    storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
    }),
  },

  getters: {
    experienceType: (state) => state.type,
  },

  actions: {
    update(courseExperience) {
      this.type = courseExperience;
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExperience, import.meta.hot))
}
