export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const pulseUrl = config.public.pulseAPIURL

  nuxtApp.postData = async function postData(endpoint, data) {
    const response = await fetch(pulseUrl + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    });
    return response
  }
})