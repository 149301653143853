// plugins/optimizely-plugin.js

export default defineNuxtPlugin(() => {
  if (process.client) {
    const script = document.createElement('script');
    script.src = 'https://cdn.optimizely.com/js/19075480916.js';
    script.async = true;
    document.head.appendChild(script);
  }
});
