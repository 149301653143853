import { defineStore } from 'pinia'

export const useRoutes = defineStore('routes', {
  state: () => ({
    list: []
  }),

  persist: {
    storage: persistedState.localStorage,
  },

  getters: {
    getReferrer: (state) => {
      const index = state.list.indexOf('/account')
      if (index != -1) {
        return index;
      }

      return -1;
    },
    length: (state) => state.list.length
  },

  actions: {
    add(route) {
      this.list.push(route)
    },
    clean() {
      this.list = [];
    },
    get(index) {
      if (index >= 0) {
        return this.list[index];
      }
      
      return null;
    },
    getList() {
      return this.list;
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRoutes, import.meta.hot))
}
