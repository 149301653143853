import { useRoutes } from "~/stores/routes";
import { useVisitorStore } from "~/stores/visitorStore";
import * as Sentry from "@sentry/nuxt";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getRedirects } = useRedirectData();
  const { data, error } = await getRedirects();
  const { getHeroHeader } = useGlobalFeaturesData();
  const {getCourseBySlug} = useCourseData();
  const {getLocationBySlug} = useLocationData()

  try {
    if (data.value) {
      for (const redirect of data.value) {
        if (to.fullPath.replace(/\/$/, "") === redirect.attributes.from) {
          return navigateTo(redirect.attributes.to, {
            external: true,
            redirectCode: 301,
          });
        } else if (redirect.attributes.from.includes('{segment}')) {
          const segmentPlaceholder = '{segment}';
          const regex = /\/sitemap(?:\/)?(.*)/;

          const match = to.fullPath.match(regex);
          if (match) {
            const dynamicPart = match[1];
            const fromPath = redirect.attributes.from.replace(segmentPlaceholder, dynamicPart);
            if (to.fullPath === fromPath) {
              return navigateTo(redirect.attributes.to, {
                external: true,
                redirectCode: 301,
              });
            }
          }
        }
      }
    }
    
    // Checking navbar settings
    to.params.navColor = 'dark';
    to.params.toggleHeroHeader = false
    to.params.navbar = 'default';
    let heroSettings = {
      displayHero: false
    };
    
    const routes = useRoutes();
    routes.add(`${from.fullPath}`)
    
    
    // Get all request headers and update the visitor store
    const headers = useRequestHeaders(['cloudfront-viewer-latitude', 'cloudfront-viewer-longitude', 'cloudfront-viewer-postal-code','cloudfront-viewer-country-region', 'cloudfront-viewer-country-region-name', 'cloudfront-viewer-city', 'host']);
    const visitorStore = useVisitorStore();

    const host = headers['host'] || '';

    if (host.includes('idtech.com')) {
      visitorStore.update({
        latitude: headers.hasOwnProperty('cloudfront-viewer-latitude')  ? headers['cloudfront-viewer-latitude'] : '0',
        longitude: headers.hasOwnProperty('cloudfront-viewer-longitude') ? headers['cloudfront-viewer-longitude'] : '0',
        zipCode: headers.hasOwnProperty('cloudfront-viewer-postal-code') ? headers['cloudfront-viewer-postal-code'] : '',
        city: headers.hasOwnProperty('cloudfront-viewer-city') ? headers['cloudfront-viewer-city'] : '',
        region: headers.hasOwnProperty('cloudfront-viewer-country-region') ? headers['cloudfront-viewer-country-region'] : '',
        regionName: headers.hasOwnProperty('cloudfront-viewer-country-region-name') ? headers['cloudfront-viewer-country-region-name'] : '',
        host: headers['host']
      }); 
    }
    
    switch (to.name) {
      case 'slug':
        const slug = to.path === '/' ? 'home' : (to.params.slug.length === 2 && to.params.slug[1] === '' ? [to.params.slug[0]] : to.params.slug);
        const { data: page } = await getHeroHeader('pages', slug);
        heroSettings = page.value;
        to.params.showPromoBanner = page.value.showPromoBanner;
        to.params.showTopNavMenu = page.value.showTopNavMenu;
        to.params.showFooterMenu = page.value.showFooterMenu;
      break;
      case 'courses-list':
        const { data: catalog } = await getHeroHeader('course-catalog');
        heroSettings = catalog.value;
        to.params.previousUrl = from.fullPath
      break;
      case 'courses-slug':
        const {data: coursePage, error: courseError} = await getCourseBySlug(to.params.slug);
        if (courseError.value) {
          to.params.courseError = courseError;          
        }
        else {
          to.params.showPromoBanner = coursePage.value.showPromoBanner;
          to.params.courseData = coursePage;
          to.params.courseError = null;
        }
      break;
      case 'locations-state-slug':
        const {data: locationPage, error: locationError} = await getLocationBySlug(to.params.slug);

        if (locationError.value) {
          to.params.locationError = locationError;
        }
        else {
          to.params.showPromoBanner = locationPage.value.entity.showPromoBanner;
          to.params.locationData = locationPage;
          to.params.locationError = null;
        }        
      break;
      case 'locations':
        const { data: locations } = await getHeroHeader('location-catalog');
        heroSettings = locations.value;
      break;
      case 'brochure-request':
        const { data: br } = await getHeroHeader('brochure-request-page');
        heroSettings = br.value;
      break;
      case 'blog':
        const { data: blog } = await getHeroHeader('blog');
        heroSettings = blog.value;
      break;
      case 'location-search':
        const { data: ls } = await getHeroHeader('locations-search-results-setting');
        heroSettings = ls.value;
      break;
    }
    
    if (heroSettings.displayHero && heroSettings.heroHeader !== null) {
      to.params.navColor = heroSettings.heroHeader.topNavColor;
      to.params.toggleHeroHeader = heroSettings.heroHeader !== null
      to.params.navbar = heroSettings.heroHeader === null ? 'default' : 'overriden'
    } 
    else if (heroSettings.displayHero) {
      to.params.navColor = 'light';
      to.params.toggleHeroHeader = true
      to.params.navbar = 'overriden';
    }

  } catch (e) {
    console.error(e.message);
    console.error(e.stack);
    Sentry.captureException(e);
  }
});


