import { defineStore } from 'pinia'

export const useInterest = defineStore('interest', {
  state: () => ({
    type: 'All',
    route: ''
  }),

  persist: {
    storage: persistedState.localStorage
  },

  getters: {
    interestType: (state) => state.type,
    currentRoute: (state) => state.route
  },

  actions: {
    update(courseInterest) {
      this.type = courseInterest;
      const route = useRoute();
      this.route = route.path
    },
    reset() {
      this.type = 'All'
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInterest, import.meta.hot))
}