import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAnimatedStatistics, LazyCardCourseGenre, LazyCardsImageContent, LazyCardsProduct, LazyCardsProgram, LazyCardsPromotion, LazyCardsTopic, LazyCarousel, LazyCarouselContent, LazyChatService, LazyContentGradientImageOverlay, LazyCourseDetails, LazyCourseRecAges, LazyCourseRecEmail, LazyCourseRecGenre, LazyCourseRecLearningStyle, LazyCourseRecName, LazyCourseRecParentName, LazyCourseRecPrograms, LazyCourseRecSearch, LazyExpander, LazyFilterCardProducts, LazyFloatingBlockFooterDesktop, LazyFloatingBlockGeolocationLookup, LazyFloatingBlockLeadGen, LazyFloatingBlockLocationSearch, LazyFloatingBlockPricesDates, LazyFloatingBlockSelectPackage, LazyFloatingBlockSelectProgram, LazyFloatingBlockViewLocations, LazyFullWidthForm, LazyGenericContent, LazyGeoLocationCards, LazyGeolocationResults, LazyGlobalLocationSearch, LazyGridImageContent, LazyImageExpander, LazyImageList, LazyLocationCatalog, LazyMap, LazyMediaHTML, LazyOverlayContent, LazyReviews3Wide, LazyTrustPilot } from '#components'
const lazyGlobalComponents = [
  ["AnimatedStatistics", LazyAnimatedStatistics],
["CardCourseGenre", LazyCardCourseGenre],
["CardsImageContent", LazyCardsImageContent],
["CardsProduct", LazyCardsProduct],
["CardsProgram", LazyCardsProgram],
["CardsPromotion", LazyCardsPromotion],
["CardsTopic", LazyCardsTopic],
["Carousel", LazyCarousel],
["CarouselContent", LazyCarouselContent],
["ChatService", LazyChatService],
["ContentGradientImageOverlay", LazyContentGradientImageOverlay],
["CourseDetails", LazyCourseDetails],
["CourseRecAges", LazyCourseRecAges],
["CourseRecEmail", LazyCourseRecEmail],
["CourseRecGenre", LazyCourseRecGenre],
["CourseRecLearningStyle", LazyCourseRecLearningStyle],
["CourseRecName", LazyCourseRecName],
["CourseRecParentName", LazyCourseRecParentName],
["CourseRecPrograms", LazyCourseRecPrograms],
["CourseRecSearch", LazyCourseRecSearch],
["Expander", LazyExpander],
["FilterCardProducts", LazyFilterCardProducts],
["FloatingBlockFooterDesktop", LazyFloatingBlockFooterDesktop],
["FloatingBlockGeolocationLookup", LazyFloatingBlockGeolocationLookup],
["FloatingBlockLeadGen", LazyFloatingBlockLeadGen],
["FloatingBlockLocationSearch", LazyFloatingBlockLocationSearch],
["FloatingBlockPricesDates", LazyFloatingBlockPricesDates],
["FloatingBlockSelectPackage", LazyFloatingBlockSelectPackage],
["FloatingBlockSelectProgram", LazyFloatingBlockSelectProgram],
["FloatingBlockViewLocations", LazyFloatingBlockViewLocations],
["FullWidthForm", LazyFullWidthForm],
["GenericContent", LazyGenericContent],
["GeoLocationCards", LazyGeoLocationCards],
["GeolocationResults", LazyGeolocationResults],
["GlobalLocationSearch", LazyGlobalLocationSearch],
["GridImageContent", LazyGridImageContent],
["ImageExpander", LazyImageExpander],
["ImageList", LazyImageList],
["LocationCatalog", LazyLocationCatalog],
["Map", LazyMap],
["MediaHTML", LazyMediaHTML],
["OverlayContent", LazyOverlayContent],
["Reviews3Wide", LazyReviews3Wide],
["TrustPilot", LazyTrustPilot],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
