<script setup>
const props = defineProps({
  menuItem: Object,
})
const ctaClass = `btn ${props.menuItem.buttonStyle} small d-none d-lg-block`;
const buttonHexCodeStyle = {
  backgroundColor: props.menuItem.buttonHexCode,
};

let cart = {};
let storageType;

// Check if window is defined (client-side)
if (typeof window !== 'undefined') {
  storageType = window.sessionStorage;
}

const supported = storageType && storageType !== null;

// Show the cart count
const cartCount = ref(0);
const shoppingCart = useShoppingCart()

watch(() => shoppingCart, () => {
  cartCount.value = shoppingCart.count
}, {immediate: true, deep: true})

onMounted(() => {
  // Listener for the cart count event
  const cartInfoListener = ({ detail: data }) => {
    updateCartStorageType(data, storageType);
  };

  // Check if window is defined (client-side)
  if (typeof window !== 'undefined') {
    document.addEventListener('idp-cartinfo', cartInfoListener);

    // Cleanup the listener on component unmount
    onBeforeUnmount(() => {
      document.removeEventListener('idp-cartinfo', cartInfoListener);
    });
  }
});

/**
 * Update the sessionStorage with count value
 */
const updateCartStorageType = (data, storageType) => {
  cart.count = data.count;
  shoppingCart.update(data.count)
};
</script>

<template>
  <li class="nav-item px-3 px-md-0 py-1 py-lg-0" :class="menuItem.component === 'top-navigation.icon' ? 'icon-item' : ''">
    <a class="nav-link p-2" aria-current="page" :href="menuItem.url" v-if="menuItem.component === 'top-navigation.link' && !menuItem.angular" prefetch>
      {{ menuItem.text }}
    </a>
    <a class="nav-link p-2" aria-current="page" :href="menuItem.url" v-if="menuItem.component === 'top-navigation.link' && menuItem.angular">
      {{ menuItem.text }}
    </a>
    <a class="nav-link btn-request-brochure p-2" aria-current="page" :href="menuItem.buttonLink" v-if="menuItem.component === 'top-navigation.cta'" prefetch>
      <UIButton :class="ctaClass" :style="buttonHexCodeStyle">
        {{ menuItem.buttonText }}
      </UIButton>
      <span class="d-block d-lg-none">{{ menuItem.buttonText }}</span>
    </a>
    <a class="nav-link btn-shopping-cart d-none d-lg-block" aria-current="page" :href="`${menuItem.url}`" :title="menuItem.text" v-if="menuItem.component === 'top-navigation.icon'">
      <span class="bi bi-cart3"></span>
      <span class="cart-count" v-if="cartCount > 0">{{ cartCount }}</span>
    </a>
    <a class="nav-link d-block d-lg-none p-2 mobile" aria-current="page" :href="menuItem.url" v-if="menuItem.component === 'top-navigation.mobile'" prefetch>
      {{ menuItem.text }}
    </a>
  </li>
</template>

<style lang="scss">
.nav-item {
  &.icon-item {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
</style>

