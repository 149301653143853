import { defineStore } from 'pinia'

export const useShoppingCart = defineStore('shoppingCart', {
  state: () => ({
    count: 0
  }),

  persist: {
    storage: persistedState.localStorage
  },

  getters: {
    getCount: (state) => state.count,
  },

  actions: {
    update(quantity) {
      this.count = quantity;
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShoppingCart, import.meta.hot))
}
