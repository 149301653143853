import { defineStore } from 'pinia'

export const useVisitorStore = defineStore('visitorInfo', {
  state: () => ({    
    latitude: '',
    longitude: '',
    zipCode: '',
    city: '',
    host: '',
    region: ''
  }),

  persist: {
    storage: persistedState.cookies,
  },

  actions: {
    update(location) {
      this.longitude = location.longitude;
      this.latitude = location.latitude;
      this.zipCode = location.zipCode;
      this.city = location.city;
      this.host = location.host;
      this.region = location.region;
    },
    
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVisitorStore, import.meta.hot))
}
