export const useStrapiAsyncData = () => {
  const config = useRuntimeConfig()  
  const apiToken = config.public.strapiAPIToken;
  
  const find = async (type, transform) => {
    // const {find} = useStrapi();
    const url = `${config.public.strapiURL}/api/${type}`; 
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }, 
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiToken}`
      }, 
      transform: transform
    });
  }

  const findOne = async (type, params, transform) => {
    
    const url = `${config.public.strapiURL}/api/${type}/${params.id}${params.populate}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }, 
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiToken}`
      }, 
      transform: transform
    });
    
    
  }

  const findOneBySlug = async (type, params, transform) => {
    // const url = `${config.public.strapiURL}/api/${type}/findOneBySlug/${params.slug}`;
    const url = `/api/find-one-by-slug?slug=${params.slug}&entity=${params.entity}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
    
  }

  const customService = async (type, service, params, transform, method = 'GET') => {
    let query = '?';
    for (const key in params) {
      query = query.concat(`${key}=${params[key]}&`);
    }
    const paramsQuery = query.slice(0,-1);
    const url = `${config.public.strapiURL}/api/${type}/${service}/${paramsQuery}`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }, 
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiToken}`
      }, 
      transform: transform
    });

  }

  

  return {
    find, 
    findOne,
    findOneBySlug,
    customService
  }
}