export const useUtils = () => {
  const sanitize = (object) => {
    try {
      if (Array.isArray(object)) {
        cleanArray(object)
      } else {
        cleanObject(object)
      }
    }
    catch (e) {
      console.log(e)
    }
    return object;
  }

  // Function for looping arrays and cleaning objects inside
  function cleanArray(array) {
    for (let index in array) {
      cleanObject(array[index])
    }
  }
  
  // Function to clean objects
  function cleanObject(object){
    for (let property in object) {
      if (property === '__component') {
        // Rename component slug
        object['component'] = object[property]
        delete object[property]
      } else if (object[property] !== null && (object[property].data && object[property].data.attributes)) {
        // Clean data.attributes properties from object to simplify
        object[property] = object[property].data.attributes;
        sanitize(object[property])
      } else if (typeof object[property] === 'object' && object[property] !== null) {       
        if (object[property].data !== undefined && object[property].data === null) {
          object[property] = null;
        } else {
        // If the property is an object, we clean the object inside of it
        cleanObject(object[property])
        }
      } else if (Array.isArray(object[property])){
        // Call clean array to clean each object in the array
        cleanArray(object[property])
      }
    }
  }

  function urlRegisterFunction(getParams = null, flagForChart = false) {
    let url = '/register#/reg-flow';
  
    if (flagForChart) {
      url += '/avail-charts-lock';
    }
  
    if (
      getParams &&
      /^\?((lid|gnrid|exid|did)(=[0-9]+)(&(lid|gnrid|exid|prodid|did)(=[0-9]+)?)*)$/.test(getParams)
    ) {
      url += getParams;
    }
  
    if (flagForChart) {
      url += '&rgnad=true';
    }
  
    return url;
  }
  
  return {
    sanitize,
    urlRegisterFunction,
  }
}
